












































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import Slot from '@/models/Slot.model';
import {SlotType} from '@/enums/SlotType.enum';
import {DateTime} from 'luxon';
import {ShiftType} from '@/enums/ShiftType.enum';
import SlotRepository from '@/api/repositories/Slot.repository';
import {RepositoryFactory} from '@/api/RepositoryFactory';
import ShipArrival from '@/models/ShipArrival.model';
import {namespace} from 'vuex-class';
import {slotStoreActions} from '@/store/slot.store';

const slotRepository: SlotRepository = RepositoryFactory.get('slot');
const SlotStore = namespace('slot');

enum MoveSlotChecked {
  SAME_DATA,
  NOT_CHECKED,
  POSSIBLE,
  NOT_POSSIBLE,
  FINISHED,
  ERROR
}

@Component({
  components: {
    SlotBookingsTableComponent: () => import(
        /* webpackChunkName: "SlotBookingsTableComponent" */
        '@/components/slotBookings/SlotBookingsTable.component.vue'),
  }
})
export default class MoveSlotComponent extends Vue {

  @Prop({default: () => new Slot()})
  public bookedSlot!: Slot;

  @Prop({default: () => new ShipArrival()})
  public shipArrival!: ShipArrival;

  @SlotStore.Action(slotStoreActions.MOVE_SLOT)
  public moveSlotAction!: (payload: { slot: Slot, date: string, shift: ShiftType }) => Promise<Slot>;

  public SlotType = SlotType;
  public MoveSlotChecked = MoveSlotChecked;
  public dateMenu: boolean = false;
  public selectedDate: string = '';

  public isLoading = false;

  public slotMoveResult: { availableSlots: number } | null = null;
  public moveSlotChecked: MoveSlotChecked = MoveSlotChecked.NOT_CHECKED;

  public selectedShift: ShiftType = ShiftType.FIRST;
  public filterItems: { text: string, value: ShiftType }[] = [{
    text: 'SHIFTS.FIRST',
    value: ShiftType.FIRST
  }, {
    text: 'SHIFTS.SECOND',
    value: ShiftType.SECOND
  }, {
    text: 'SHIFTS.THIRD',
    value: ShiftType.THIRD
  }];

  public get getLocale() {
    if (this.$i18n.locale === 'en') {
      return 'en-US';
    } else {
      return 'de-DE';
    }
  }

  public mounted() {
    const slotDate = DateTime.fromISO(this.bookedSlot.date);
    this.selectedDate = slotDate.toFormat('yyyy-MM-dd');
    this.selectedShift = this.bookedSlot.shift;
  }

  public async dateFilterChanged() {
    // @ts-ignore
    (this.$refs.menu as any).save(this.selectedDate);
    this.checkNewSettings();
  }

  /**
   * Check if the new settings are on the same day and shift
   */
  public checkNewSettings() {
    const slotDate = DateTime.fromISO(this.bookedSlot.date).toFormat('dd.MM.yyyy');
    const selectedDate = DateTime.fromFormat(this.selectedDate, 'yyyy-MM-dd');
    const formattedString = selectedDate.toFormat('dd.MM.yyyy');
    if (formattedString === slotDate && this.selectedShift === this.bookedSlot.shift) {
      this.moveSlotChecked = MoveSlotChecked.SAME_DATA;
    } else if (this.selectedDate && this.selectedShift) {
      this.checkMoveSlot();
    }
  }

  get formattedDate(): string {
    if (this.selectedDate) {
      const selectedDate = DateTime.fromFormat(this.selectedDate, 'yyyy-MM-dd');
      return selectedDate.toFormat('dd.MM.yyyy');
    } else return ''
  }

  public async checkMoveSlot() {
    try {
      this.isLoading = true;
      const response = await slotRepository.checkMoveSlot({
        slot: this.bookedSlot,
        shift: this.selectedShift, date: this.selectedDate
      });
      this.slotMoveResult = response.data;
      if (this.slotMoveResult!.availableSlots >= this.bookedSlot.amount) {
        this.moveSlotChecked = MoveSlotChecked.POSSIBLE;
      } else {
        this.moveSlotChecked = MoveSlotChecked.NOT_POSSIBLE;
      }
    } catch (e) {
      this.moveSlotChecked = MoveSlotChecked.NOT_POSSIBLE;
    } finally {
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    }
  }

  public async moveSlotClicked() {
    try {
      this.isLoading = true;
      await this.moveSlotAction({
        slot: this.bookedSlot,
        shift: this.selectedShift, date: this.selectedDate
      });
      this.moveSlotChecked = MoveSlotChecked.FINISHED;
      this.$emit('reloadCalendar', this.bookedSlot.date);
    } catch (e) {
      this.moveSlotChecked = MoveSlotChecked.ERROR;
    } finally {
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    }
  }
}

